import { FC } from 'react'
import { useAiChatAssistant } from '../Assistant/useChatAssistant'
import { flowiseAiChatService } from './flowiseService'
import { AssistantChat } from '../Assistant/AssistantChat'
import env from '../../env.json'

import useAnalytics from '../../analytics/useAnalytics'
import { ProfileWithInterests } from '@learnn/sdk/dist/api/profile'

export type FlowiseChatCourseProps = {
  courseId: string
  courseName: string
  courseDescription: string
  courseGoals: string
  courseProgress: number
  profile?: ProfileWithInterests
}

export const FlowiseChatCourse: FC<FlowiseChatCourseProps> = ({
  courseId,
  courseName,
  courseDescription,
  courseGoals,
  courseProgress,
  profile,
}) => {
  const { logAiAssistantSendMessage } = useAnalytics()
  const { messages, isLoading, sendMessage } = useAiChatAssistant({
    assistantFactory: () =>
      flowiseAiChatService(
        {
          type: 'course_assistant',
          courseId,
          courseName,
          courseDescription,
          courseGoals,
          courseProgress,
          name: profile?.name,
          lastName: profile?.lastName,
          profession: profile?.profession,
          company: profile?.company,
          companyRole: profile?.companyRole,
          bio: profile?.bio,
          competences: profile?.competences,
          goal: profile?.goal,
          interests: profile?.interests,
        },
        env.FLOWISE_COURSE_ASSISTANT_CHATFLOW_ID,
      ),
  })

  const handleSendMessage = async (message: string) => {
    await sendMessage(message)
    logAiAssistantSendMessage('course', message)
  }

  const defaultPrompts = [
    {
      label: 'Questo corso è adatto a me?',
      prompt: 'Questo corso è adatto a me?',
    },
    {
      label: 'Spiegami i concetti base di questo corso',
      prompt: `Spiegami i concetti base di questo corso in maniera sintetica `,
    },
    {
      label: 'Genera un quiz',
      prompt: `Genera un quiz a risposta multipla sugli argomenti del corso`,
    },
    {
      label: 'Genera un business case',
      prompt: `Genera un business case e fammi una sola domanda a cui devo rispondere in merito agli argomenti del corso`,
    },
  ]

  return (
    <AssistantChat
      messages={messages}
      loadingForResponse={isLoading}
      onSendMessage={handleSendMessage}
      defaultPrompts={defaultPrompts}
    />
  )
}
