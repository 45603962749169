import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import { HelmetProvider, Helmet } from 'react-helmet-async'
import './App.css'

import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'

import { FreeRegistrationScreen } from './screens/FreeRegistrationScreen'
import { SocialRedirectScreen } from './screens/SocialRedirectScreen'
import { PaymentScreen } from './screens/PaymentScreen'
import { BillingScreen } from './screens/BillingScreen'
import { LoginScreen } from './screens/LoginScreen'
import { MaintenanceScreen } from './screens/MaintenanceScreen'
import { ForgotPasswordScreen } from './screens/ForgotPasswordScreen'
import MyScreen from './screens/My'
import ErrorScreen from './screens/Error'
import LogoutScreen from './screens/Logout'
import { GiftRegistrationScreen } from './screens/GiftRegistrationScreen'
import { GiftPaymentScreen } from './screens/GiftPaymentScreen/'
import { YearRecapScreen } from './screens/YearRecapScreen'
import { HomeScreen } from './screens/HomeScreen'
import { FastScreen } from './screens/FastScreen'
import { ExploreScreen } from './screens/ExploreScreen'
import { WallScreen } from './screens/WallScreen'
import { PlayerScreen } from './screens/PlayerScreen'
import { CourseScreen } from './screens/CourseScreenNew'
import { MyLearnnScreen } from './screens/MyLearnnScreen'
import { ComingSoonScreen } from './screens/ComingSoonScreen'
import { JobsScreen } from './screens/JobsScreen'
import { QuizScreen } from './screens/QuizScreen'
import { DailyQuizScreen } from './screens/DailyQuizScreen'
import { DailyListScreen } from './screens/DailyListScreen'
import { NotFoundScreen } from './screens/NotFoundScreen'
import { ResetPasswordScreen } from './screens/ResetPasswordScreen'
import VersionScreen from './screens/Version'
import { graphqlClient } from './controllers/apollo'
import { GlobalProvider } from './components/GlobalProvider'
import env from './env.json'
import { FullscreenContextProvider } from './components/LessonPlayer/controls/fullscreenContext'
import { ShareContextProvider } from './components/SharePopup/shareContext'
import { CoursePopupContextProvider } from './components/CoursePopup/popupContext'
import { ExpertPopupContextProvider } from './components/ExpertPopup/popupContext'
import { AssistantContextProvider } from './components/AssistantPopup/assistantContext'
import { PlayerSideContextProvider } from './components/LessonPlayer/PlayerSideContent/playerSideContext'
import { SharePopup } from './components/SharePopup'
import { CoursePopup } from './components/CoursePopup'
import { ExpertPopup } from './components/ExpertPopup'
import { AssistantPopup } from './components/AssistantPopup'
import { Autologin } from './components/Autologin'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CompletedCoursesScreen } from './screens/CompletedCoursesScreen'
import { InfiniteQuizScreen } from './screens/InfiniteQuizScreen'
import { InfiniteIntroScreen } from './screens/InfiniteIntroScreen'
import { ProfileScreen } from './screens/ProfileScreen'
import { PreferencesScreen } from './screens/PreferencesScreen'
import { ReminderScreen } from './screens/ReminderScreen'
import { CollabScreen } from './screens/Collab'
import { SubscriptionScreen } from './screens/SubscriptionScreen'
import { ChangePlanScreen } from './screens/ChangePlan'
import { CancelSubscriptionScreen } from './screens/CancelSubscription'
import { InviteScreen } from './screens/Invite'
import { DashboardB2BScreen } from './screens/DashboardB2B'
import { LiveScreen } from './screens/Live'
import { CommunityScreen } from './screens/Community'
import { FAQScreen } from './screens/FAQ'
import { PostJobScreen } from './screens/PostJob'
import { IntegrationsScreen } from './screens/IntegrationsScreen'
import { PartnerScreen } from './screens/Partner'
import { LearnnAssistantScreen } from './screens/LearnnAssistant'
import { MenuProvider } from './components/Navigation/menuContext'
import { ContinueWatchingPopupContextProvider } from './components/ContinueWatchingPopup/popupContext'
import { FeatureFlagProvider } from './components/FeatureFlag/featureFlagContext'

import {
  OfflineTicketScreen,
  OfflineSoonScreen,
  LandingOfflineScreen,
  OfflineVideoScreen,
  OfflineCommunityScreen,
  OfflineTelegramScreen,
  OfflineInfoScreen,
} from './screens/OfflineScreens'
import { ToolsScreen } from './screens/Tools'
import { ExpertAziendeScreen } from './screens/ExpertScreen/Aziende'
import { ExpertSelezioneScreen } from './screens/ExpertScreen/Selezione'
import { ExpertCandidatiScreen } from './screens/ExpertScreen/Candidati'
import { ExpertScopriScreen } from './screens/ExpertScreen/Scopri'
import { ExpertTutelaScreen } from './screens/ExpertScreen/Tutela'
import { ExpertTrovaScreen } from './screens/ExpertScreen/Trova'
import { ExpertMissioneScreen } from './screens/ExpertScreen/Missione'
import { CheckoutScreen } from './screens/Checkout/CheckoutScreen'
import {
  ProjectFeedbackScreen,
  TechFeedbackScreen,
  ContentFeedbackScreen,
  ToolsFeedbackScreen,
} from './screens/FeedbackScreens'

import {
  MakeApplyScreen,
  MakeLeaderboardScreen,
  MakeCommunityScreen,
  MakeWebinarScreen,
} from './screens/MakeScreens'
import { VerifiedScreen } from './screens/VerifiedScreen'
import { PathScreen } from './screens/PathScreen'
import { PathListScreen } from './screens/PathScreen'
import * as Sentry from '@sentry/react'
import { AppShell, defaultTheme } from '@learnn/designn'
import { DeleteAccountScreen } from './screens/DeleteAccountScreen'

Sentry.init({
  dsn: 'https://55167144662847a583fc5ee99d8e5bb8@o1308172.ingest.sentry.io/4505085855203328',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: env.NODE_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

function ProtectedScreens() {
  return (
    <GlobalProvider>
      <FeatureFlagProvider>
        <AssistantContextProvider>
          <MenuProvider>
            <ShareContextProvider>
              <CoursePopupContextProvider>
                <ExpertPopupContextProvider>
                  <FullscreenContextProvider>
                    <PlayerSideContextProvider>
                      <ContinueWatchingPopupContextProvider>
                        <Switch>
                          <PrivateRoute
                            path='/player/:lessonId'
                            component={PlayerScreen}
                            name='Player'
                          />
                          <PrivateRoute
                            path='/lezione/:lessonId'
                            component={PlayerScreen}
                            name='Lesson'
                          />
                          <PrivateRoute
                            exact
                            path='/progressi'
                            component={HomeScreen}
                            name='Home'
                          />
                          <PrivateRoute exact path='/fast' component={FastScreen} name='Fast' />
                          <PrivateRoute exact path='/' component={ExploreScreen} name='Explore' />
                          <PrivateRoute
                            path='/salvati'
                            component={MyLearnnScreen}
                            name='MyLearnn'
                          />
                          <PrivateRoute
                            path='/ai'
                            component={LearnnAssistantScreen}
                            name='LearnnAssistant'
                          />
                          <PrivateRoute
                            path='/completati'
                            component={CompletedCoursesScreen}
                            name='CompletedCourses'
                          />
                          <PrivateRoute
                            path='/contenuti'
                            component={WallScreen}
                            name='AllCourses'
                          />
                          <PrivateRoute
                            path='/in-arrivo'
                            component={ComingSoonScreen}
                            name='ComingContents'
                          />
                          <PrivateRoute
                            path='/jobs'
                            component={() => <Redirect to='/' />}
                            name='Jobs'
                          />
                          <PrivateRoute path='/corso/:id' component={CourseScreen} name='Course' />
                          <PrivateRoute
                            path='/test/corso/:courseId'
                            component={QuizScreen}
                            name='CourseQuiz'
                          />
                          <PrivateRoute
                            path='/sfide/verified'
                            component={VerifiedScreen}
                            name='VerifiedList'
                          />
                          <PrivateRoute
                            path='/sfide/:quizId'
                            component={DailyQuizScreen}
                            name='DailyQuiz'
                          />
                          <PrivateRoute
                            path='/sfide'
                            component={DailyListScreen}
                            name='DailyQuizList'
                          />
                          <PrivateRoute
                            path='/infinite'
                            component={InfiniteIntroScreen}
                            name='InfiniteQuizIntro'
                          />
                          <PrivateRoute
                            path='/sfida'
                            component={InfiniteQuizScreen}
                            name='InfiniteQuiz'
                          />
                          <PrivateRoute path='/my' component={MyScreen} name='My' />
                          <PrivateRoute
                            path='/reset-password'
                            component={ResetPasswordScreen}
                            name='ResetPassword'
                          />
                          <PrivateRoute
                            path='/integrations'
                            component={() => <Redirect to='/' />}
                            name='Integrations'
                          />
                          <PrivateRoute
                            path='/modifica-profilo'
                            component={ProfileScreen}
                            name='ProfileScreen'
                          />
                          <PrivateRoute
                            path='/preferenze'
                            component={PreferencesScreen}
                            name='PreferencesScreen'
                          />
                          <PrivateRoute
                            path='/reminder'
                            component={ReminderScreen}
                            name='ReminderScreen'
                          />
                          <PrivateRoute path='/collab' component={CollabScreen} name='Collab' />
                          <PrivateRoute
                            path='/abbonamento'
                            component={SubscriptionScreen}
                            name='SubscriptionDetails'
                          />
                          <PrivateRoute
                            path='/elimina-account'
                            component={DeleteAccountScreen}
                            name='DeleteAccount'
                          />
                          <PrivateRoute path='/invita' component={InviteScreen} name='Invite' />
                          <PrivateRoute
                            path='/dashboard-b2b'
                            component={DashboardB2BScreen}
                            name='DashboardB2B'
                          />
                          <PrivateRoute path='/live' component={LiveScreen} name='live' />
                          <PrivateRoute
                            path='/community'
                            component={CommunityScreen}
                            name='community'
                          />
                          <PrivateRoute path='/faq' component={FAQScreen} name='Faq' />
                          <PrivateRoute
                            path='/job-post'
                            component={() => <Redirect to='/' />}
                            name='PostJob'
                          />
                          <PrivateRoute
                            path='/offline/biglietto'
                            component={() => <Redirect to='/' />}
                            name='OfflineTicket'
                          />
                          <PrivateRoute
                            path='/offline/video-presentazione'
                            component={() => <Redirect to='/' />}
                            name='OfflinePresentation'
                          />
                          <PrivateRoute
                            path='/offline/community'
                            component={() => <Redirect to='/' />}
                            name='OfflineCommunity'
                          />
                          <PrivateRoute
                            path='/offline/telegram'
                            component={() => <Redirect to='/' />}
                            name='OfflineTelegram'
                          />
                          <PrivateRoute
                            path='/offline/info'
                            component={() => <Redirect to='/' />}
                            name='OfflineInfo'
                          />
                          <PrivateRoute
                            path='/offline/soon'
                            component={() => <Redirect to='/' />}
                            name='OfflineSoon'
                          />
                          <PrivateRoute
                            path='/offline'
                            component={() => <Redirect to='/' />}
                            name='Offline'
                          />
                          <PrivateRoute path='/tools' component={ToolsScreen} name='Tools' />
                          <PrivateRoute
                            path='/scopri-expert'
                            component={ExpertScopriScreen}
                            name='Scopri Expert'
                          />
                          <PrivateRoute
                            path='/expert'
                            component={ExpertTrovaScreen}
                            name='Trova Expert'
                          />
                          <PrivateRoute
                            path='/missione-expert'
                            component={ExpertMissioneScreen}
                            name='Missione'
                          />
                          <PrivateRoute
                            path='/garanzia-expert'
                            component={ExpertTutelaScreen}
                            name='Garanzia Expert'
                          />
                          <PrivateRoute
                            path='/selezione-expert'
                            component={ExpertSelezioneScreen}
                            name='Selezione Expert'
                          />
                          <PrivateRoute
                            path='/candidati-expert'
                            component={ExpertCandidatiScreen}
                            name='Candidati Expert'
                          />
                          <PrivateRoute
                            path='/aziende-expert'
                            component={ExpertAziendeScreen}
                            name='Aziende Expert'
                          />
                          <PrivateRoute
                            path='/feedback/progetto'
                            component={ProjectFeedbackScreen}
                            name='FeedbackProject'
                          />
                          <PrivateRoute
                            path='/feedback/contenuti'
                            component={ContentFeedbackScreen}
                            name='FeedbackContent'
                          />
                          <PrivateRoute
                            path='/feedback/tech'
                            component={TechFeedbackScreen}
                            name='FeedbackTech'
                          />
                          <PrivateRoute
                            path='/feedback/tools'
                            component={ToolsFeedbackScreen}
                            name='FeedbackTools'
                          />
                          <PrivateRoute
                            path='/make/community'
                            component={() => <Redirect to='/' />}
                            name='MakeCommunity'
                          />
                          <PrivateRoute
                            path='/make/webinar'
                            component={() => <Redirect to='/' />}
                            name='MakeWebinar'
                          />
                          <PrivateRoute
                            path='/make/leaderboard'
                            component={() => <Redirect to='/' />}
                            name='MakeLeaderboard'
                          />
                          <PrivateRoute
                            path='/make'
                            component={() => <Redirect to='/' />}
                            name='Make'
                          />
                          <PrivateRoute
                            path='/corsi/verified'
                            component={VerifiedScreen}
                            name='VerifiedList'
                          />
                          <PrivateRoute path='/paths/:slug' component={PathScreen} name='Path' />
                          <PrivateRoute path='/paths' component={PathListScreen} name='Paths' />
                          <PrivateRoute path='/partner' component={PartnerScreen} name='Partner' />
                          <PrivateRoute
                            path='/checkout'
                            component={CheckoutScreen}
                            name='Checkout'
                          />
                          <PublicRoute path='*' component={NotFoundScreen} name='NotFound' />
                        </Switch>
                        <AppShell theme={defaultTheme}>
                          <CoursePopup />
                          <ExpertPopup />
                          <SharePopup />
                          <AssistantPopup provider='flowise' />
                        </AppShell>
                      </ContinueWatchingPopupContextProvider>
                    </PlayerSideContextProvider>
                  </FullscreenContextProvider>
                </ExpertPopupContextProvider>
              </CoursePopupContextProvider>
            </ShareContextProvider>
          </MenuProvider>
        </AssistantContextProvider>
      </FeatureFlagProvider>
    </GlobalProvider>
  )
}

function App() {
  const queryClient = new QueryClient()

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        <Helmet>
          <script type='text/javascript'>
            {(function () {
              var t = document.createElement('script')
              ;(t.type = 'text/javascript'),
                (t.async = !0),
                (t.src = 'https://cdn.firstpromoter.com/fprom.js'),
                (t.onload = t.onreadystatechange =
                  function () {
                    var t = this.readyState
                    if (!t || 'complete' == t || 'loaded' == t)
                      try {
                        $FPROM.init(env.FP_ID, '.learnn.com')
                      } catch (t) {}
                  })
              var e = document.getElementsByTagName('script')[0]
              e.parentNode.insertBefore(t, e)
            })()}
          </script>
        </Helmet>
        <ApolloProvider client={graphqlClient}>
          <ShareContextProvider>
            <Router>
              <Autologin>
                <Switch>
                  <PublicRoute path='/pagamento/' component={PaymentScreen} />
                  <PublicRoute path='/fatturazione/' component={BillingScreen} />
                  <PublicRoute path='/version/' component={VersionScreen} />
                  <PublicRoute path='/login/' component={LoginScreen} />
                  <PublicRoute path='/manutenzione/' component={MaintenanceScreen} />
                  <PublicRoute path='/crea-account/' component={FreeRegistrationScreen} />
                  <PublicRoute path='/social-redirect/' component={SocialRedirectScreen} />
                  <PublicRoute
                    path='/checkout/:plan'
                    component={() => <Redirect to='/crea-account' />}
                  />
                  <PublicRoute
                    path='/password/'
                    component={() => <Redirect to='/crea-account' />}
                  />
                  <PublicRoute path='/regalo/' component={GiftRegistrationScreen} />
                  <PublicRoute path='/regalo-dettagli/' component={GiftPaymentScreen} />
                  <PublicRoute path='/forgot' component={ForgotPasswordScreen} />
                  <PublicRoute path='/error' component={ErrorScreen} />
                  <PublicRoute path='/logout' component={LogoutScreen} />
                  <PublicRoute path='/year-recap/:userId' component={YearRecapScreen} />
                  <PublicRoute path='/reset-password/:code' component={ResetPasswordScreen} />
                  <ProtectedScreens />
                </Switch>
              </Autologin>
            </Router>
            <SharePopup />
          </ShareContextProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
