import { useBodyClass } from '../../../../utils/hooks'
import { Title, useTheme, Container } from '@learnn/designn'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { FlowiseChatCourse } from 'src/components/FlowiseChat/FlowiseChatCourse'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { matchQuery } from '../../../../utils/matchQuery'
import { getUserProfileWithInterests } from 'src/controllers/Profile'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'

export type AssistantSectionProps = {
  course: CourseBaseInfoEngagement
}

export const AssistantSection = ({ course }: AssistantSectionProps) => {
  useBodyClass('app')
  const { spacing } = useTheme()

  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const profileQuery = useQuery(['profile', userId], () => getUserProfileWithInterests(userId))

  return (
    <Container>
      <Title variant='heading2xl' mb={spacing.space_6}>
        Assistente AI
      </Title>
      {
        <>
          {matchQuery(profileQuery, {
            loading: () => <></>,
            error: () => (
              <FlowiseChatCourse
                courseId={course.id}
                courseName={course.title}
                courseDescription={course.description ?? ''}
                courseGoals={course.youLearn ?? ''}
                courseProgress={course.progress}
              />
            ),
            success: profile =>
              pipe(
                profile,
                O.fold(
                  () => (
                    <FlowiseChatCourse
                      courseId={course.id}
                      courseName={course.title}
                      courseDescription={course.description ?? ''}
                      courseGoals={course.youLearn ?? ''}
                      courseProgress={course.progress}
                    />
                  ),
                  profile => (
                    <FlowiseChatCourse
                      courseId={course.id}
                      courseName={course.title}
                      courseDescription={course.description ?? ''}
                      courseGoals={course.youLearn ?? ''}
                      courseProgress={course.progress}
                      profile={profile}
                    />
                  ),
                ),
              ),
          })}
        </>
      }
    </Container>
  )
}
