import { FC } from 'react'
import { Swiper } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper-bundle.css'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { NavigationOptions } from 'swiper/types/components/navigation'
// import Swiper core and required components
import SwiperCore, { Navigation } from 'swiper'

export type CarouselKinds = 
  | 'course'
  | 'webinar'
  | 'featured'
  | 'lesson'
  | 'lastCourse'
  | 'wideLesson'
  | 'paymentFour'
  | 'paymentFive'
  | 'profile'
  | 'expert'
export type ICarousel = {
  children: any
  options?: SwiperOptions
  navigation?: NavigationOptions
  grabCursor?: boolean
  kind: CarouselKinds
} & Swiper

// install Swiper components
SwiperCore.use([Navigation])

const mobileFeatured = {
  slidesPerView: 1.1,
  spaceBetween: 16,
}
const mobileLastCourse = {
  slidesPerView: 1,
  spaceBetween: 16,
}
const mobile = {
  slidesPerView: 1.7,
  spaceBetween: 16,
}
const mobileWebinar = {
  slidesPerView: 2.5,
  spaceBetween: 16,
}
const mobileLesson = {
  slidesPerView: 1.2,
  slidesPerGroup: 1.2,
  spaceBetween: 20,
}

const featuredBreakpoints = {
  360: mobileFeatured,
  375: mobileFeatured,
  480: mobileFeatured,
  640: {
    slidesPerView: 1.7,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1.7,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 2.2,
    spaceBetween: 20,
  },
  1600: {
    slidesPerView: 2.2,
    spaceBetween: 30,
  },
  1930: {
    slidesPerView: 2.2,
    spaceBetween: 30,
  },
}

const courseBreakpoints = {
  360: mobile,
  375: mobile,
  480: mobile,
  640: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 30,
  },
  1250: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 30,
  },
}

const profileBreakpoints = {
  360: mobileLastCourse,
  375: mobileLastCourse,
  480: mobileLastCourse,
  640: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 1.4,
    slidesPerGroup: 1.4,
    spaceBetween: 30,
  },
  1200: {
    slidesPerView: 1.7,
    slidesPerGroup: 1.7,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 1.7,
    slidesPerGroup: 1.7,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 1.8,
    slidesPerGroup: 1.8,
    spaceBetween: 30,
  },
}

const lastCourseBreakpoints = {
  360: mobileLastCourse,
  375: mobileLastCourse,
  480: mobileLastCourse,
  640: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1.2,
    slidesPerGroup: 1.2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 1.2,
    slidesPerGroup: 1.2,
    spaceBetween: 30,
  },
  1200: {
    slidesPerView: 1.5,
    slidesPerGroup: 1.5,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 1.5,
    slidesPerGroup: 1.5,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 1.5,
    slidesPerGroup: 1.5,
    spaceBetween: 30,
  },
}

const webinarBreakpoints = {
  360: mobileWebinar,
  375: mobileWebinar,
  480: mobileWebinar,
  640: {
    slidesPerView: 5.3,
    slidesPerGroup: 5.3,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 6.3,
    slidesPerGroup: 6.3,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 6.3,
    slidesPerGroup: 6.3,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 6.3,
    slidesPerGroup: 6.3,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 6.3,
    slidesPerGroup: 6.3,
    spaceBetween: 30,
  },
}

const lessonBreakpoints = {
  360: mobileLesson,
  375: mobileLesson,
  480: mobileLesson,
  640: mobileLesson,
  844: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 20,
  },
  1250: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 30,
  },
  1920: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 40,
  },
}

const wideLessonBreakpoints = {
  360: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  375: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  480: {
    slidesPerView: 1.5,
    spaceBetween: 16,
  },
  640: {
    slidesPerView: 2.5,
    slidesPerGroup: 2.5,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.5,
    slidesPerGroup: 2.5,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 15,
  },
  1250: {
    slidesPerView: 3.3,
    slidesPerGroup: 3.3,
    spaceBetween: 15,
  },
  1600: {
    slidesPerView: 3.3,
    slidesPerGroup: 3.3,
    spaceBetween: 15,
  },
  1920: {
    slidesPerView: 3.3,
    slidesPerGroup: 3.3,
    spaceBetween: 15,
  },
}

const paymentFiveBreakpoints = {
  360: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  480: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 4.5,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 4.5,

    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 4.5,

    spaceBetween: 10,
  },
  1250: {
    slidesPerView: 5,

    spaceBetween: 10,
  },
  1600: {
    slidesPerView: 5,

    spaceBetween: 10,
  },
  1920: {
    slidesPerView: 5,

    spaceBetween: 10,
  },
}

const paymentFourBreakpoints = {
  360: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  375: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  480: {
    slidesPerView: 3.5,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 4,

    spaceBetween: 10,
  },
  1024: {
    slidesPerView: 4,

    spaceBetween: 10,
  },
  1250: {
    slidesPerView: 4,

    spaceBetween: 10,
  },
  1600: {
    slidesPerView: 4,

    spaceBetween: 10,
  },
  1920: {
    slidesPerView: 4,

    spaceBetween: 10,
  },
}


const expertBreakpoints = {
  360: mobile,
  375: mobile,
  480: {
    slidesPerView: 2.2,
    slidesPerGroup: 2.2,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 2.5,
    slidesPerGroup: 2.5,
    spaceBetween: 15,
  },
  768: {
    slidesPerView: 2.8,
    slidesPerGroup: 2.8,
    spaceBetween: 15,
  },
  1024: {
    slidesPerView: 3.2,
    slidesPerGroup: 3.2,
    spaceBetween: 15,
  },
  1250: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
  },
  1600: {
    slidesPerView: 4.4,
    slidesPerGroup: 4.4,
    spaceBetween: 20,
  },
  1920: {
    slidesPerView: 4.6,
    slidesPerGroup: 4.6,
    spaceBetween: 20,
  },
}

const carouselKinds = {
  course: courseBreakpoints,
  expert: expertBreakpoints,
  webinar: webinarBreakpoints,
  featured: featuredBreakpoints,
  lesson: lessonBreakpoints,
  wideLesson: wideLessonBreakpoints,
  lastCourse: lastCourseBreakpoints,
  profile: profileBreakpoints,
  paymentFour: paymentFourBreakpoints,
  paymentFive: paymentFiveBreakpoints,
}

export const Carousel: FC<ICarousel> = ({
  children,
  options,
  kind,
  navigation,
  grabCursor = true,
  ...props
}) => {
  return (
    <Swiper
      lazy
      grabCursor={grabCursor}
      navigation={navigation ?? false}
      breakpoints={carouselKinds[kind]}
      {...options}
      {...props}>
      {children}
    </Swiper>
  )
}
