import { extractThumbUrlFromImage, calculateLessonDuration, extractCourseAuthorAndProfession, } from '../utils';
import { progressiveSearch } from './progressiveSearch';
const hideDraft = (lesson) => !lesson.draft;
export const smartSearch = (client, oramaClient, engagement) => {
    const searchByKeyword = async (keyword, limit = 100) => {
        const { coursesIndex, lessonsIndex, pathsIndex, expertsIndex, coursesSearchConfig, lessonsSearchConfig, pathsSearchConfig, expertsSearchConfig, } = oramaClient;
        let courses, lessons, paths, experts;
        try {
            const pathsResult = await pathsIndex.search({
                term: keyword,
                limit: limit,
                mode: pathsSearchConfig.mode,
            });
            paths =
                pathsResult?.hits.filter(x => x.score > pathsSearchConfig.threshold).map(x => x.document) ??
                    [];
        }
        catch (e) {
            paths = [];
        }
        try {
            const coursesResult = await coursesIndex.search({
                term: keyword,
                limit: limit,
                mode: coursesSearchConfig.mode,
            });
            courses =
                coursesResult?.hits
                    .filter(x => x.score > coursesSearchConfig.threshold)
                    .map(x => x.document) ?? [];
        }
        catch (error) {
            courses = [];
        }
        try {
            const lessonsResult = await lessonsIndex.search({
                term: keyword,
                limit: limit,
                mode: lessonsSearchConfig.mode,
            });
            lessons =
                lessonsResult?.hits
                    .filter(x => x.score > lessonsSearchConfig.threshold)
                    .map(x => x.document) ?? [];
        }
        catch (error) {
            lessons = [];
        }
        try {
            const expertsResult = await expertsIndex.search({
                term: keyword,
                limit: limit,
                mode: expertsSearchConfig.mode,
            });
            experts =
                expertsResult?.hits
                    .filter(x => x.score > expertsSearchConfig.threshold)
                    .map(x => x.document) ?? [];
        }
        catch (error) {
            experts = [];
        }
        return {
            courses: courses.map(toCourse),
            lessons: lessons.map(toLesson),
            paths: paths.map(toPath),
            experts: experts.map(toExpert),
        };
    };
    const toCourse = (c) => {
        return {
            ...c,
            id: String(c.id),
            type: c.type,
            authors: c.authors && c.authors.length > 0 ? c.authors.map(extractCourseAuthorAndProfession) : [],
            coverImage: extractThumbUrlFromImage(c.coverImage, 'medium') ?? '',
            companyLogo: extractThumbUrlFromImage(c.companyLogo, 'small') ?? '',
        };
    };
    const toLesson = (l) => {
        const { course, coverImage } = l;
        return {
            ...l,
            id: String(l.id),
            coverImageUrl: extractThumbUrlFromImage(coverImage, 'medium') ?? '',
            duration: calculateLessonDuration(l.duration ?? 0),
            durationSeconds: l.duration,
            category: course?.title ?? 'LEZIONE',
            free: l.free ?? false,
        };
    };
    const toPath = (p) => {
        return {
            ...p,
            image: extractThumbUrlFromImage(p.image, 'large'),
        };
    };
    const toExpert = (p) => {
        return {
            ...p,
        };
    };
    return {
        searchByKeyword: searchByKeyword,
        progressiveSearch: progressiveSearch(searchByKeyword, engagement),
    };
};
