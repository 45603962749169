import { FC, useContext, useEffect, useState } from 'react'
import QueryString from 'query-string'
import style from '../../screens/HomeScreen/homeScreen.module.css'
import { DynamicSectionsState } from '@learnn/sdk/dist/api/dynamic-sections/types'
import { SearchBar } from '../SearchBar'
import { SearchResults } from '../SearchResults/SearchResults'
import { RenderSection } from './RenderSections'
import { GlobalProviderContext } from '../GlobalProvider'
import * as O from 'fp-ts/Option'
import { useReactive } from '../../utils/useReactive'
import { progressiveSearch, ProgressiveSearchContent } from '../../controllers/Search'
import * as lib from 'fp-ts/function'
import { ErrorMessage } from '../ErrorMessage'
import Loader from 'react-loader-spinner'
import useAnalytics from '../../analytics/useAnalytics'
import { useHistory } from 'react-router-dom'
import { VerticalStack } from '@learnn/designn'

export type SearchComponentProps = {
  searchQuery: string
  onSearch: (query: string) => unknown
  onClear: () => unknown
}
export const SearchComponent: FC<SearchComponentProps> = ({ searchQuery, onSearch, onClear }) => {
  const globalContext = useContext(GlobalProviderContext)
  const { logSelectSearchItem } = useAnalytics()
  const { data, error } = useReactive<ProgressiveSearchContent, string>({
    observableFn$: progressiveSearch(searchQuery),
    queryKey: ['search', searchQuery],
  })

  const renderError = () => (
    <div className={style.container}>
      {' '}
      <ErrorMessage />{' '}
    </div>
  )

  const render = (result: O.Option<ProgressiveSearchContent>) => (
    <div className={style.searchContainer}>
      <SearchBar
        bordered
        containerClass='mt-0'
        onSearch={onSearch}
        onClear={onClear}
        filters={globalContext?.configuration.searchCategories}
        initialKeyword={searchQuery}
      />
      {O.isNone(result) ? (
        <div className={style.containerLoading}>
          <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
        </div>
      ) : (
        <VerticalStack>
          <SearchResults
            onResultClick={c => logSelectSearchItem(searchQuery, c.type.slug, c.id, c.title, '')}
            onClear={onClear}
            data={result.value}
          />
        </VerticalStack>
      )}
    </div>
  )

  return (
    <VerticalStack>
      {
        lib.pipe(
          error,
          O.fold(() => render(data), renderError),
        )
      }
    </VerticalStack>
  )
}

export type DynamicSectionsProps = {
  state: DynamicSectionsState
}
export const DynamicSections = ({ state }: DynamicSectionsProps) => {
  const [currentKeyword, setKeyword] = useState<O.Option<string>>(O.none)
  const history = useHistory()

  const searchKey = QueryString.parse(location.search)?.find?.toString()

  useEffect(() => {
    if (searchKey) {
      setKeyword(O.some(searchKey))
      window.scrollTo({ top: 0, behavior: 'auto' })
    } else {
      setKeyword(O.none)
    }
  }, [searchKey])

  return lib.pipe(
    currentKeyword,
    O.fold(
      () => (
        <>
          {Object.keys(state).map((key, i) => {
            return (
              <RenderSection
                //@ts-ignore //TODO
                section={state[key]}
                key={`section-${i}`}
                onSearch={(keyword: string) => {
                  setKeyword(O.some(keyword))
                  history.push('/?find=' + keyword)
                  window.scrollTo({ top: 0, behavior: 'auto' })
                }}
              />
            )
          })}
        </>
      ),
      keyword => (
        <SearchComponent
          searchQuery={keyword}
          onSearch={(keyword: string) => {
            setKeyword(O.some(keyword))
            history.push('/?find=' + keyword)
            window.scrollTo({ top: 0, behavior: 'auto' })
          }}
          onClear={() => {
            setKeyword(O.none)
            history.push('/')
            window.scrollTo({ top: 0, behavior: 'auto' })
          }}
        />
      ),
    ),
  )
}
