import {
  AiChatAssistantService,
  AssistantConfiguration,
  SourceType,
} from '../Assistant/assistantService'
import env from '../../env.json'
import { FlowiseSourceDocumentsMetadata, FlowisePredictionResponse } from './types'
import { isOnlyCourseRetrieverToolUsed, isOnlyLessonRetrieverToolUsed } from './utils'
import { isCourseCompletedByPercentage } from '@learnn/sdk/dist/api/utils'

export const createFlowiseAiChatAssistantService =
  (flowiseUrl: string) =>
  (assistantConfig: AssistantConfiguration, chatflowId: string): AiChatAssistantService => {
    let chatId: string
    let systemPrompt: string
    let onTokenReceiveCallback: ((token: string) => Promise<unknown>) | undefined
    let onMessageReceiveCallback:
      | ((data: {
          message: string
          sources: SourceType<FlowiseSourceDocumentsMetadata>[]
        }) => Promise<unknown>)
      | undefined

    const getErrorMessageByStatus = (code: number) => {
      switch (code) {
        case 429:
          return 'Hai raggiunto il numero massimo di richieste, riprova più tardi.'

        default:
          return 'Si è veriricato un errore durante la generazione della risposta'
      }
    }

    const getOverrideConfigFromAssistantConfig = () => {
      switch (assistantConfig.type) {
        case 'player_assistant':
          systemPrompt = `
                    Sei l'assistente AI di Learnn ed in questo momento stai supportando nell'apprendimento di una particolare lezione.
                    La lezione si chiama ${assistantConfig.lessonName} ed è presente nel corso ${assistantConfig.courseName}.
                    Il tuo compito è quello di supportare l'utente nell'apprendimento quindi devi spiegare i dubbi che ha l'utente o approfondire eventuali argomenti.
                    L'utente può anche chiederti di fare dei quiz o delle domande, in quel caso fai una domanda a scelta multipla e correggi la risposta dell'utente.
                    Le domande devono essere pratiche.

                    Le risposte devono essere attinenti ai contenuti di questa lezione e agli obiettivi e interessi dell'utente.

                    ${USER_INFO}

                    Di seguito la trascrizione della lezione:
                    ${assistantConfig.transcript}
      `
          return {
            systemMessagePrompt: {
              chatPromptTemplate_0: systemPrompt,
            },
          }
        case 'course_assistant':
          systemPrompt = `
                    Sei l'assistente AI di Learnn e stai supportando un utente di Learnn nell'apprendimento di uno specifico corso.
                    Il corso si chiama "${
                      assistantConfig.courseName
                    }". Di seguito descrizione e obiettivi:

                    
                    DESCRIZIONE:
                    ${assistantConfig.courseDescription}

                    
                    OBIETTIVI
                    ${assistantConfig.courseGoals}

                    Devi rispondere alle domande dell'utente in modo semplice, chiaro e sintetico. Tutte le tue risposte devono essere relative a questo corso. 
                    Le risposte devono essere utili per l'utente tenendo conto del suo obiettivo e dei suoi interessi.

                    ${
                      isCourseCompletedByPercentage(assistantConfig.courseProgress)
                        ? "L'utente ha completato il corso."
                        : assistantConfig.courseProgress > 0
                        ? `L'utente ha visto il corso all' ${assistantConfig.courseProgress}%`
                        : `L'utente non ha ancora iniziato il corso.`
                    }

                     ${USER_INFO}

                    Usa al massimo trecento parole. Tra tutte le domande è possibile che l'utente ti chieda:
                      - Generazione di quiz: l'utente può chiederti di generare delle domande a risposta multipla su un determinato argomento presente nel corso. Genera una domanda corta con un elenco di quattro risposte plausibili di cui una sola corretta ordinate casualmente. Dovrai poi correggere la risposta dell'utente e consigliargli la lezione in cui trovarla. 
                      - Generazione di business case: l'utente può chiederti di generare una domanda aperta su un determinato argomento. Devi inventare una situazione plausibile e chiedere all'utente di fare una scelta. Giudica la sua risposta fornendo anche la risposta corretta consigliando una lezione per approfondire. 
                      - Consiglia lezioni specifiche di questo corso che possano aiutare l'utente a raggiungere i suoi obiettivi formativi. 
                      - Chiarimenti generici: l'utente può chiedere chiarimenti sugli argomenti del corso, rispondi chiarendo i dubbi e indicando i contenuti più indicati per lui.   
                `
          return {
            systemMessage: {
              toolAgent_0: systemPrompt,
            },
            pineconeMetadataFilter: {
              pinecone_0: {
                courseId: {
                  $eq: assistantConfig.courseId,
                },
              },
            },
          }
        case 'global_assistant':
          systemPrompt = `
                      Sei l'assistente AI di Learnn e il tuo compito è supportare l'utente nel suo percorso di apprendimento.
                      Il tuo scopo principale è rispondere in modo chiaro, semplice e sintetico alle domande dell'utente sfruttando le informazioni di corsi e lezioni di Learnn.
                      
                      Di seguito una lista delle tipologie di domande a cui dovrai dare risposta:
                      - Generazione di quiz: l'utente può chiederti di generare delle domande a risposta multipla su un determinato argomento. In questo caso dovrai generare una breve domanda e fornire quattro risposte plausibili di cui una sola corretta. Dovrai poi correggere la risposta dell'utente e consigliargli la lezione o il corso in cui trovarla. 
                      - Generazione di business case: l'utente può chiederti di generare delle domande aperte su un determinato argomento. Devi inventare una situazione plausibile e chiedere all'utente di fare delle scelte. Giudica la sua risposta fornendo anche la risposta corretta e consigliando un corso o lezione per approfondire.
                      - Consiglia corsi o lezioni che possano aiutare l'utente a raggiungere i suoi obiettivi formativi. 
                      - Chiarimenti generici: l'utente può chiedere chiarimenti generici sull'uso della piattaforma, rispondi indicando i contenuti più indicati per lui.
                    
                      Se l'utente pone una domanda non pertinente ai contenuti di Learnn, rispondi educatamente che non puoi fornire assistenza in merito.
                      Raccomanda sempre corsi e lezioni attinenti, tenendo in considerazione gli obiettivi e gli interessi dell'utente. 
                                          
                      ${USER_INFO}

                      Mantieni un tono amichevole e adattati alle esigenze dell'utente in ogni interazione.
                      Devi rispondere alle domande dell'utente in modo semplice, chiaro e sintetico.
                      Devi chiarire i dubbi e approfondire eventuali argomenti se richiesto.
                      Se necessario devi consigliare corsi o lezioni da seguire.
                      La risposta che dai deve essere relativa ai contenuti di Learnn.
                  `
          return {
            systemMessage: {
              toolAgent_0: systemPrompt,
            },
          }
      }
    }

    const handleSourceDocuments = (
      message: FlowisePredictionResponse,
    ): SourceType<FlowiseSourceDocumentsMetadata>[] => {
      return message.sourceDocuments
        ? message.sourceDocuments.reduce(
            (prev: SourceType<FlowiseSourceDocumentsMetadata>[], source) => {
              if (isOnlyCourseRetrieverToolUsed(message)) {
                if (prev.some(x => x.type === 'course' && x.courseId === source.metadata.courseId))
                  return prev
                return [
                  ...prev,
                  {
                    type: 'course',
                    courseId: source.metadata.courseId,
                    content: source.pageContent,
                    metadata: source.metadata,
                  },
                ]
              }
              if (isOnlyLessonRetrieverToolUsed(message)) {
                if (prev.some(x => x.type === 'lesson' && x.lessonId === source.metadata.lessonId))
                  return prev
                return [
                  ...prev,
                  {
                    type: 'lesson',
                    lessonId: source.metadata.lessonId,
                    content: source.pageContent,
                    metadata: source.metadata,
                  },
                ]
              }
              return prev
            },
            [],
          )
        : []
    }

    const applySourcesToAssistantResponse = ({
      message,
      sources,
    }: {
      message: string
      sources: SourceType<FlowiseSourceDocumentsMetadata>[]
    }) => {
      sources.forEach(source => {
        const pageContent = source.content

        const getSourceLink = () => {
          switch (source.type) {
            case 'lesson': {
              return `${window.location.protocol}//${window.location.host}/player/${source.lessonId}`
            }
            case 'course': {
              return `${window.location.protocol}//${window.location.host}/corso/${source.courseId}`
            }
          }
        }
        message = message.replace(RegExp(pageContent, 'g'), match => {
          return `[${match}](${getSourceLink()})`
        })
      })
      return message
    }

    const USER_INFO = `
      DETTAGLI UTENTE:
      Nome:
      ${assistantConfig.name}

      Cognome: 
      ${assistantConfig.lastName}

      Descrizione:
      ${assistantConfig.bio}

      Professione:
      ${assistantConfig.companyRole} @ ${assistantConfig.company}

      Ruolo:
      ${assistantConfig.profession}

      Obiettivo:
      ${assistantConfig.goal}

      Competenze:
      ${assistantConfig.competences?.join(', ')}

      Interessi:
      ${assistantConfig.interests?.map(x => x.title).join(', ')}

      Mantieni un tono amichevole e adattati alle esigenze dell'utente in ogni interazione.
      Devi rispondere alle domande dell'utente in modo semplice, chiaro e sintetico.
      Devi chiarire i dubbi e approfondire eventuali argomenti se richiesto.
      Se necessario devi consigliare corsi o lezioni da seguire.
      La risposta che dai deve essere relativa ai contenuti di Learnn.
  `

    return {
      sendMessage: async (message: string) => {
        new Promise(async () => {
          const response = await fetch(`${flowiseUrl}/api/v1/prediction/${chatflowId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              chatId,
              question: message,
              overrideConfig: getOverrideConfigFromAssistantConfig(),
            }),
          })

          if (!response || response.status !== 200) {
            if (onMessageReceiveCallback) {
              await onMessageReceiveCallback({
                message: getErrorMessageByStatus(response.status),
                sources: [],
              })
            }
            return
          }

          const result: FlowisePredictionResponse = await response.json()
          chatId = result.chatId

          if (onMessageReceiveCallback) {
            if (result.text === undefined || result.text === null || result.text === '') {
              await onMessageReceiveCallback({
                message: getErrorMessageByStatus(500),
                sources: [],
              })
            } else {
              const sources = handleSourceDocuments(result)
              const response = applySourcesToAssistantResponse({
                message: result.text,
                sources: sources,
              })
              await onMessageReceiveCallback({
                message: response,
                sources: sources,
              })
            }
          }
          console.debug('Flowise Service: user message sent', result)
        })
      },

      onTokenReceive: callback => {
        onTokenReceiveCallback = callback
      },

      onMessageReceive: callback => {
        onMessageReceiveCallback = callback
      },
    }
  }

export const flowiseAiChatService = createFlowiseAiChatAssistantService(env.FLOWISE_URL)
