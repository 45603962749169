import { useEffect } from 'react'
import Header from 'src/components/Header'
import { LiveChat } from 'src/components/LiveChat'
import { CheckoutData, CheckoutProvider, useCheckout } from './CheckoutContext'
import style from './paymentScreen.module.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  CheckoutDetails,
  CheckoutDetailsSkeleton,
} from './components/CheckoutDetails/CheckoutDetails'
import Container from 'react-bootstrap/Container'
import {
  PrimaryMethodPayment,
  PrimaryMethodPaymentSkeleton,
} from './components/PrimaryMethodPayment/PrimaryMethodPayment'
import { SubmitButton, SubmitButtonSkeleton } from './components/SubmitButton/SubmitButton'
import { FC, useContext } from 'react'
import { GlobalProviderContext } from 'src/components/GlobalProvider'
import QueryString from 'query-string'
import env from '../../env.json'
import { InvoiceDetails } from './components/InvoiceDetails'
import { getAnalyticsProductVariant, removeParamsFromSearch } from './utils'
import { useGTMCheckout } from '../../analytics/gtm'

export const Checkout: FC<CheckoutProps> = ({ title, ctaText }) => {
  const { checkoutState } = useCheckout()

  const renderCheckoutState = () => {
    switch (checkoutState.state) {
      case 'loading':
        return (
          <>
            <Row className='justify-content-center'>
              <Col xs={12} md={12} lg={12}>
                <h1 className={style.title}>
                  {title ?? 'Completa il pagamento ed entra ora su Learnn'}
                </h1>
              </Col>
              <Col xs={12} md={5} lg={4}></Col>
            </Row>
            <Row className='justify-content-center'>
              <Col xs={12} md={12} lg={12}>
                <PrimaryMethodPaymentSkeleton />
              </Col>
              <Col xs={12} md={12} lg={12} className='mt-4'>
                <h4 className={style.summaryTitle}>Riepilogo</h4>
                <div className={style.summary}>
                  <CheckoutDetailsSkeleton />
                  <div style={{ marginTop: 20 }}>
                    <SubmitButtonSkeleton />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )
      case 'ready':
        return (
          <>
            <Row className='justify-content-center'>
              <Col xs={12} md={12} lg={12}>
                <h1 className={style.title}>
                  {title ?? 'Completa il pagamento ed entra ora su Learnn'}
                </h1>
              </Col>
              <Col xs={12} md={5} lg={4}></Col>
            </Row>
            <Row className='justify-content-center'>
              <Col xs={12} md={12} lg={12}>
                <PrimaryMethodPayment />
              </Col>
              <Col xs={12} md={12} lg={12} className='mt-4'>
                <InvoiceDetails />
              </Col>
              <Col xs={12} md={12} lg={12} className='mt-4'>
                <h4 className={style.summaryTitle}>Riepilogo</h4>
                <div className={style.summary}>
                  <CheckoutDetails />
                  <div style={{ marginTop: 20 }}>
                    <SubmitButton text={ctaText} />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )
      case 'error': {
        if (window.zE) window.zE('messenger', 'open')
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              Si è verificato un errore{checkoutState.message ? `: ${checkoutState.message}` : ''}
            </span>
            <span>Contatta l'assistenza per essere supportato.</span>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <LiveChat />
      <Header type='light' />
      <Container className={style.container}>{renderCheckoutState()}</Container>
    </div>
  )
}

type CheckoutProps = {
  title?: string
  ctaText?: string
}

type UrlParams = {
  plan?: string
  coupon?: string
  addon?: string
  addon_qty?: string
  plan_qty?: string
  test_payments?: string
  next_step?: string
}
const extractPlan = (urlParams: UrlParams) => urlParams?.plan
const extractNextStep = (urlParams: UrlParams) => urlParams?.next_step
const extractCoupon = (urlParams: UrlParams) => urlParams?.coupon
const extractAddon = (urlParams: UrlParams) => urlParams?.addon
const extractAddonQuantity = (urlParams: UrlParams) =>
  urlParams?.addon_qty ? Number(urlParams.addon_qty) : undefined
const extractPlanQuantity = (urlParams: UrlParams) =>
  urlParams?.plan_qty ? Number(urlParams.plan_qty) : undefined

export const CheckoutScreen = () => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const userEmail = globalContext?.userEmail
  const urlParams: UrlParams = QueryString.parse(location.search)
  const { sendToGTM } = useGTMCheckout()

  useEffect(() => {
    window.history.pushState(
      null,
      '',
      removeParamsFromSearch(`${location.pathname}${location.search}`),
    )
  }, [])

  return (
    <CheckoutProvider
      initialCheckoutData={{
        plan: extractPlan(urlParams),
        planQuantity: extractPlanQuantity(urlParams),
        coupon: extractCoupon(urlParams),
        addon: extractAddon(urlParams),
        addonQuantity: extractAddonQuantity(urlParams),
        nextStep: extractNextStep(urlParams),
        userId: userId ?? '',
      }}
      onPaymentSuccess={(checkoutData, invoiceData) => {
        const defaultRedirect =
          checkoutData.plan === 'abbonamento-annuale' || checkoutData.plan === '7d-trial-annuale'
            ? env.YEARLY_THANKYOU_URL
            : env.THANKYOU_URL
        const paramsRedirect = checkoutData.nextStep
          ? decodeURIComponent(checkoutData.nextStep)
          : undefined
        const locationSearch = removeParamsFromSearch(location.search ?? '')
        const redirectUrl = paramsRedirect ?? defaultRedirect
        const separator = redirectUrl.includes('?') ? '&' : '?'

        if (invoiceData.type === 'addon') {
          sendToGTM({
            event: 'purchase_addon',
            user_data_email: userEmail,
            checkoutType: 'cambio-piano',
            actionState: 'only-ticket',
            couponName: checkoutData.coupon,
            addon_id: checkoutData.addon,
            addon_qty: checkoutData.addonQuantity,
            addon_value: invoiceData.invoiceAmount,
          })
        } else {
          sendToGTM({
            event: 'checkoutCompleted',
            user_data_email: userEmail,
            checkoutType: 'cambio-piano',
            actionState: invoiceData.actionState,
            transactionId: invoiceData.uuid,
            productName: invoiceData.planName,
            productSKU: checkoutData.plan,
            productVariant: getAnalyticsProductVariant(invoiceData.actionState),
            price: invoiceData.total && invoiceData.total / 100,
            trial: false,
            discount: invoiceData.discount,
            discountAmount: invoiceData.discountAmount && invoiceData.discountAmount / 100,
            couponName: checkoutData.coupon,
            addon_id: checkoutData.addon,
            addon_qty: checkoutData.addon ? checkoutData.addonQuantity ?? 1 : undefined,
            addon_value: invoiceData.addonValue && invoiceData.addonValue / 100,
          })
        }

        //Timeout needed for analytics
        setTimeout(() => {
          window.location.replace(
            redirectUrl + (locationSearch ? separator + locationSearch.substring(1) : ''),
          )
        }, 2000)
      }}
      redirectToPayment={checkoutData => {
        function convertToUrlParams(data: CheckoutData): UrlParams {
          const urlParams: UrlParams = {
            plan: data.plan,
            coupon: data.coupon,
            addon: data.addon,
            addon_qty: data.addonQuantity?.toString(),
            plan_qty: data.planQuantity?.toString(),
          }

          Object.keys(urlParams).forEach(
            key =>
              urlParams[key as keyof UrlParams] === undefined &&
              delete urlParams[key as keyof UrlParams],
          )

          return urlParams
        }

        const queryString = new URLSearchParams(convertToUrlParams(checkoutData)).toString()
        const newUrl = `/pagamento/?${queryString}`

        window.location.replace(newUrl)
      }}>
      <Checkout title='Completa il pagamento' ctaText='CONTINUA ➔' />
    </CheckoutProvider>
  )
}
