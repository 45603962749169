import { FlowiseChatPlayer } from 'src/components/FlowiseChat/FlowiseChatPlayer'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { GlobalProviderContext } from '../../GlobalProvider'
import { matchQuery } from '../../../utils/matchQuery'
import { getUserProfileWithInterests } from 'src/controllers/Profile'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/function'

type AssistantTabProps = {
  courseName: string
  lessonName: string
  transcript: string
}
export const AssistantTab = ({ courseName, lessonName, transcript }: AssistantTabProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const profileQuery = useQuery(['profile', userId], () => getUserProfileWithInterests(userId))

  return (
    <>
      {matchQuery(profileQuery, {
        loading: () => <></>,
        error: () => (
          <FlowiseChatPlayer
            courseName={courseName}
            lessonName={lessonName}
            transcript={transcript}
          />
        ),
        success: profile =>
          pipe(
            profile,
            O.fold(
              () => (
                <FlowiseChatPlayer
                  courseName={courseName}
                  lessonName={lessonName}
                  transcript={transcript}
                />
              ),
              profile => (
                <FlowiseChatPlayer
                  courseName={courseName}
                  lessonName={lessonName}
                  transcript={transcript}
                  profile={profile}
                />
              ),
            ),
          ),
      })}
    </>
  )
}
