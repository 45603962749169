import { FC } from 'react'
import { useAiChatAssistant } from '../Assistant/useChatAssistant'
import { flowiseAiChatService } from './flowiseService'
import { AssistantChat } from '../Assistant/AssistantChat'
import env from '../../env.json'
import { ProfileWithInterests } from '@learnn/sdk/src/api/profile'
import useAnalytics from '../../analytics/useAnalytics'

export type FlowiseChatGlobalProps = {
  profile?: ProfileWithInterests
}
export const FlowiseChatGlobal: FC<FlowiseChatGlobalProps> = ({ profile }) => {
  const { logAiAssistantSendMessage } = useAnalytics()

  const { messages, isLoading, sendMessage } = useAiChatAssistant({
    assistantFactory: () =>
      flowiseAiChatService(
        {
          type: 'global_assistant',
          name: profile?.name,
          lastName: profile?.lastName,
          profession: profile?.profession,
          company: profile?.company,
          companyRole: profile?.companyRole,
          bio: profile?.bio,
          competences: profile?.competences,
          goal: profile?.goal,
          interests: profile?.interests,
        },
        env.FLOWISE_GLOBAL_ASSISTANT_CHATFLOW_ID,
      ),
  })

  const handleSendMessage = async (message: string) => {
    await sendMessage(message)
    logAiAssistantSendMessage('global', message)
  }

  return (
    <AssistantChat
      messages={messages}
      loadingForResponse={isLoading}
      onSendMessage={handleSendMessage}
      defaultPrompts={[
        {
          label: 'Consigliami una lezione',
          prompt: `Consigliami una lezione e indicami anche il corso in cui trovarla`,
        },
        {
          label: 'Consigliami un corso',
          prompt: 'Consigliami un corso da seguire per raggiungere il mio obiettivo',
        },
        {
          label: 'Creami un programma di studio',
          prompt: 'Creami un programma di studio per raggiungere il mio obiettivo',
        },
        {
          label: 'Generami un business case',
          prompt: `Generami un business case a cui devo dare una risposta e valutami`,
        },
      ]}
    />
  )
}
