import React, { useEffect, useState } from 'react'
import { useAsyncMemo } from '../../utils/hooks'
import { AcData, GlobalProviderContext, IConfiguration } from './context'
import auth from '../../utils/auth'
import {
  getConfiguration,
  selfOffline,
  getActiveCampaignData,
  firstPromoterDashboard,
  refreshToken,
  self,
} from '../../controllers/User'
import { getQuizConfiguration } from '../../controllers/Quiz'
import { useHistory } from 'react-router-dom'
import { createAnalytics, getAnalyticsProviderFactory } from '../../analytics/useAnalytics'
import { Provider as AnalyticsProvider } from '@learnn/analytics/src/providers'
import { voidAnalytics } from '@learnn/analytics'
import * as Sentry from '@sentry/react'
import { SelfResponse } from '@learnn/sdk/src/api/auth'

export interface IGlobalProviderProps {
  children: React.ReactChild | React.ReactChild[]
}

export interface User {
  self: SelfResponse | null
}

export const GlobalProvider = ({ children }: IGlobalProviderProps) => {
  const history = useHistory()
  const [configuration, setConfiguration] = useState<IConfiguration>({
    homeBanner: { enable: false },
    premiumVisible: false,
  })
  const [acData, setACData] = useState<AcData>()
  const [user, setUser] = useState<User>()
  const [loading, setLoading] = useState<boolean>()
  const [analyticsProvider, setAnalyticsProvider] = useState<AnalyticsProvider>(voidAnalytics)


  useEffect(() => {
    (async () => {
      try {
        const userSelf = await self()
        if (userSelf.blocked) {
          const querystring =
            location.pathname && location.pathname !== '/login' && location.pathname !== '/'
              ? `?next_step=${location.href}`
              : ''
          history.push(`/pagamento/${querystring}`)
        } else {
          const loadConfiguration = await getConfiguration()
          const quizConfiguration = await getQuizConfiguration()
          const configurationToSet: IConfiguration = {
            ...{ homeBanner: { enable: false } },
            ...loadConfiguration,
            ...quizConfiguration,
          }

          setUser({ self: userSelf })
          setConfiguration(configurationToSet)

          const provider = getAnalyticsProviderFactory(configurationToSet)()
          setAnalyticsProvider(provider)
          await setupAnalyticsProperties(provider, userSelf)
          setupFirstPromoterProperties()

          setupActiveCampaignProperties().then(acData => {
            acData && setACData(acData)
          })

          //@ts-ignore //TODO
          Sentry.setUser(userSelf)
        }
      } catch (error) {
        try {
          await refreshToken()
          const locationData = { ...location }
          window.location.replace(locationData.href)
        } catch (error) {
          const locationData = { ...location }
          auth.clearToken()
          auth.clearRefreshToken()
          const querystring =
            locationData.pathname && locationData.pathname !== '/login'
              ? locationData.pathname !== '/'
                ? `?appUrl=${locationData.href}`
                : locationData.search !== ''
                ? `?appUrl=${locationData.href}`
                : ''
              : ''
          history.push(`/login${querystring}`)
        }
      }
    })()
  }, [])

  useAsyncMemo(
    async () => {
      try {
        setLoading(true)
        let userSelf: SelfResponse;
        try {
          userSelf = await selfOffline()
        } catch (e) {
          userSelf = await self()
        }
        setUser({ self: userSelf })        
        setLoading(false)
      } catch (error) {
        try {
          await refreshToken()
          const locationData = { ...location }
          window.location.replace(locationData.href)
        } catch (error) {
          const locationData = { ...location }
          auth.clearToken()
          auth.clearRefreshToken()
          const querystring =
            locationData.pathname && locationData.pathname !== '/login'
              ? locationData.pathname !== '/'
                ? `?appUrl=${locationData.href}`
                : locationData.search !== ''
                ? `?appUrl=${locationData.href}`
                : ''
              : ''
          history.push(`/login${querystring}`)
        }
      }
      return {}
    },
    [],
    {},
  )

  return !loading && user?.self?.id ? (
    <GlobalProviderContext.Provider
      value={{
        configuration,
        analyticsProvider,
        userId: user?.self?.id,
        userEmail: user?.self?.email,
        acData: acData,
        user: user,
        b2b: {
          teamId: user?.self?.teamId,
          role: user?.self?.teamRole,
        },
      }}>
      {children}
    </GlobalProviderContext.Provider>
  ) : null
}

async function setupAnalyticsProperties(
  provider: AnalyticsProvider,
  user: SelfResponse | null | undefined,
) {
  const analytics = createAnalytics(provider)
  if (user) {
    await analytics.setUserId(user.id)
    await analytics.logLogin()
    if (user.gaClientId) {
      await analytics.setUserProperty('gaClientId', user.gaClientId)
    }
    if (user.role?.name === 'Authenticated') {
      await analytics.setUserProperty('user_plan', 'pro')
    } else if (user.role?.name === 'Free') {
      await analytics.setUserProperty('user_plan', 'free')
    }
    await analytics.setUserProperty('role', user.role)
  }
  return analytics
}

async function setupActiveCampaignProperties() {
  const maxRetries = 10
  let currentRetry = 0

  let acData = null

  while (currentRetry < maxRetries && !acData) {
    try {
      currentRetry++
      acData = await getActiveCampaignData()
      if (acData) {
        window.ACUserData = acData
      }
    } catch (error) {
      await new Promise(resolve => setTimeout(() => resolve(true), 5000))
      console.log(error)
    }
  }
  return acData
}

async function setupFirstPromoterProperties() {
  const maxRetries = 10
  let currentRetry = 0

  let acData

  while (currentRetry < maxRetries && !acData) {
    try {
      currentRetry++
      const data = await firstPromoterDashboard()
      if (data) {
        window.FPUserData = data
      }
    } catch (error) {
      await new Promise(resolve => setTimeout(() => resolve(true), 5000))
      console.log(error)
    }
  }
}
