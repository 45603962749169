import { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import QueryString from 'query-string'
import CardPayment from '../../components/CardPayment'
import PayPalPayment from '../../components/PayPalPayment'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { faPaypal, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons'

import {
  applyCoupon,
  validatePlan,
  Addon,
  TrialPlan,
  getAddonInfo,
  ValidatedPlan,
  DiscountDetails,
  CustomPrice,
} from '../../controllers/Payment'
import { getCBPaymentData } from '../../controllers/User'
import env from '../../env.json'
import auth from '../../utils/auth'
import style from './paymentScreen.module.css'
import { RouteComponentProps } from 'react-router-dom'
import { useGTMCheckout } from '../../analytics/gtm'
import { extractPlanPeriod, removeVatFromPrice } from '../../utils/checkout'
import { EnsureRegistration } from '../../components/EnsureRegistration/EnsureRegistration'
import DirectDebitPayment from '../../components/DirectDebitPayment/DirectDebitPayment'
import StripePayment from '../../components/StripePayment/StripePayment'
import { Carousel } from '../../components/Carousel'
import { CarouselSlide } from '../../components/Carousel/CarouselSlide'
import { chargebeeInstance } from '../../utils/chargebee'
import PlanDetails, { SubmitButton } from '../../components/PlanDetails'
import { LiveChat } from '../../components/LiveChat'

interface IPaymentScreenProps {
  history: RouteComponentProps['history']
}

type UrlParams = {
  plan?: string
  coupon?: string
  addon?: string
  addon_qty?: string
  plan_qty?: string
  test_payments?: string
}

type PaymentMethod = 'card' | 'paypal' | 'apple-pay' | 'google-pay' | 'direct_debit'

export type FormActions = {
  submit(): void
}

type PaymentForms = Record<PaymentMethod, FormActions | null>

const extractToken = () => {
  const authToken = auth.getToken() as string

  return authToken
}

const extractPlan = (urlParams: UrlParams) => urlParams?.plan
const extractCoupon = (urlParams: UrlParams) => urlParams?.coupon
const extractAddon = (urlParams: UrlParams) => urlParams?.addon
const extractAddonQuantity = (urlParams: UrlParams) =>
  urlParams?.addon_qty ? Number(urlParams.addon_qty) : undefined
const extractPlanQuantity = (urlParams: UrlParams) =>
  urlParams?.plan_qty ? Number(urlParams.plan_qty) : undefined

const PaymentScreen = ({ history }: IPaymentScreenProps) => {
  const urlParams = useRef(QueryString.parse(location.search))
  const [loading, setLoading] = useState(true)
  const [paymentLoading, setLoadingPayment] = useState(false)
  const [paymentError, setPaymentError] = useState('')
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod>('card')
  const [error, setError] = useState<boolean>(false)
  const [planInfo, setPlanInfo] = useState<ValidatedPlan>()
  const [planPrice, setPlanPrice] = useState<number>()
  const [trialPlan, setTrialPlan] = useState<TrialPlan>()
  const [coupon, setCoupon] = useState<string>('')
  const [couponInvName, setCouponInvoiceName] = useState<string>('')
  const [couponPrice, setCouponPrice] = useState<number>()
  const [customPrice, setCustomPrice] = useState<CustomPrice>()
  const [discountDetails, setDiscountDetails] = useState<DiscountDetails>()
  const [creditNotes, setCreditNotes] = useState<number>()
  const [applePaySupported, enableApplePay] = useState(false)
  const [googlePaySupported, _enableGooglePay] = useState(true)
  const { sendToGTM } = useGTMCheckout()
  const [addon, setAddon] = useState<Addon>()
  const [submitVisible, toggleSubmit] = useState(false)
  const [isVatExempt, setVatExtempt] = useState(false)

  const token = extractToken()
  const plan = extractPlan(urlParams.current)
  const planQuantity = extractPlanQuantity(urlParams.current)

  const paymentFormsRef = useRef<PaymentForms>({
    card: null,
    'apple-pay': null,
    'google-pay': null,
    direct_debit: null,
    paypal: null,
  })

  const onPaymentSucceded = () => {
    const defaultRedirect =
      plan === 'abbonamento-annuale' || plan === '7d-trial-annuale'
        ? //@ts-ignore //TODO 02.01.2023
          env.YEARLY_THANKYOU_URL
        : env.THANKYOU_URL
    const paramsRedirect = urlParams?.current?.next_step
    const locationSearch = location.search ?? ''
    const redirectUrl = paramsRedirect ?? defaultRedirect

    window.location.replace(redirectUrl + locationSearch)
  }

  const submitCoupon = async () => {
    try {
      let retrievedAddon: Addon
      const paramsAddon = await extractAddon(urlParams.current)
      const addonQuantity = await extractAddonQuantity(urlParams.current)
      if (paramsAddon) {
        try {
          retrievedAddon = await getAddonInfo(paramsAddon, addonQuantity)
          setAddon(retrievedAddon)
        } catch (error) {
          console.log(error)
        }
      }

      const retrievedCoupon = await applyCoupon({
        plan,
        coupon,
        ...(planQuantity && { planQuantity }),
        addon: paramsAddon,
        addonQuantity,
      })
      return retrievedCoupon
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    const locationSearch = location.search ?? ''
    const pagamentoUrl =
      location.search.length > 0
        ? QueryString.exclude(`/pagamento${location.search}`, [
            'plan',
            'coupon',
            'addon',
            'addon_qty',
            'next_step',
            'plan_qty',
          ])
        : '/pagamento'
    window.history.pushState(null, '', pagamentoUrl)
    const checkState = async () => {
      let retrievedAddon: Addon

      if (auth.getToken()) {
        try {
          const { cardAlreadyPresent, promotionalCredits } = await getCBPaymentData()
          if (cardAlreadyPresent) {
            window.location.replace(`/checkout/${locationSearch}`)
            return
          }
          if (promotionalCredits) {
            setCreditNotes(promotionalCredits)
          }
        } catch (error) {
          console.log(error)
          setError(true)
          return
        }
      }

      const paramsAddon = await extractAddon(urlParams.current)
      const addonQuantity = await extractAddonQuantity(urlParams.current)
      if (paramsAddon) {
        try {
          retrievedAddon = await getAddonInfo(paramsAddon, addonQuantity)
          setAddon(retrievedAddon)
        } catch (error) {
          console.log(error)
        }
      }

      if (plan) {
        try {
          const paramsCoupon = await extractCoupon(urlParams.current)
          if (paramsCoupon) {
            const retrievedPlan = await applyCoupon({
              plan,
              coupon: paramsCoupon,
              ...(planQuantity && { planQuantity }),
              addon: paramsAddon,
              addonQuantity,
            })
            const {
              invoice_name,
              base_price,
              price,
              trial,
              period,
              period_unit,
              discount,
              customPrice,
              couponInvoiceName
            } = retrievedPlan
            sendToGTM({
              event: 'checkoutStep',
              productName: invoice_name,
              productSKU: plan,
              price: price,
              subscriptionPeriod: extractPlanPeriod(period, period_unit),
              trial: trial ? true : false,
              discount: true,
              checkoutStep: 3,
            })
            if (trial) {
              setTrialPlan(trial)
            }
            setPlanInfo(retrievedPlan)
            setPlanPrice(isVatExempt ? removeVatFromPrice(base_price) : base_price)
            setCoupon(paramsCoupon)
            setCouponInvoiceName(couponInvoiceName ? couponInvoiceName : '')
            setCouponPrice(price)
            setDiscountDetails(discount)
            setCustomPrice(customPrice)
          } else {
            const retrievedPlan = await validatePlan({
              plan,
              ...(planQuantity && { planQuantity }),
              addon: paramsAddon,
              addonQuantity,
            })
            const { invoice_name, price, customPrice, base_price, trial, period, period_unit } =
              retrievedPlan
            sendToGTM({
              event: 'checkoutStep',
              productName: invoice_name,
              productSKU: plan,
              price: price,
              subscriptionPeriod: extractPlanPeriod(period, period_unit),
              trial: trial ? true : false,
              discount: false,
              checkoutStep: 3,
            })

            if (trial) {
              setTrialPlan(trial)
            }
            setPlanInfo(retrievedPlan)
            setPlanPrice(isVatExempt ? removeVatFromPrice(base_price) : base_price)
            setCustomPrice(customPrice)
          }
        } catch (error) {
          console.log(error)
          setError(true)
          window.location.replace(env.CHOOSE_PLAN_URL + locationSearch)
          return
        }
      } else if (!plan && retrievedAddon) {
        sendToGTM({
          event: 'checkoutStep',
          productName: retrievedAddon.name,
          productSKU: paramsAddon,
          price: retrievedAddon.price,
          trial: false,
          discount: false,
          checkoutStep: 3,
        })
      } else {
        console.log('No plan or addon provided')
        setError(true)
        window.location.replace(env.CHOOSE_PLAN_URL + locationSearch)
        return
      }

      setLoading(false)
    }
    const checkApplePay = () => {
      chargebeeInstance.load('apple-pay').then((applePayHandler: any) => {
        const isApplePayAvailable = applePayHandler.canMakePayments()
        if (isApplePayAvailable) {
          enableApplePay(true)
        }
      })
    }
    checkState()
    checkApplePay()
  }, [plan])

  const renderPaymentTab = () => {
    switch (selectedPayment) {
      case 'card':
        return (
          <div className={style.tabInner}>
            <CardPayment
              ref={e => {
                paymentFormsRef.current.card = e
                toggleSubmit(true)
              }}
              type='standard'
              userToken={token}
              plan={plan}
              planInfo={planInfo}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              showCoupon={couponPrice || couponPrice === 0 ? false : true} //TODO calculate coupon price with addon
              price={couponPrice ?? planPrice}
              isTrial={!!trialPlan}
              trialPeriod={trialPlan?.period}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              creditNotes={creditNotes}
              addon={addon}
              planQuantity={planQuantity}
              isVatExempt={isVatExempt}
              onVatExemptChange={v => setVatExtempt(v)}
            />
          </div>
        )
      case 'paypal':
        return (
          <div className={style.tabInner}>
            <PayPalPayment
              ref={e => {
                paymentFormsRef.current.paypal = e
                toggleSubmit(true)
              }}
              type='standard'
              userToken={token}
              plan={plan}
              planInfo={planInfo}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              showCoupon={couponPrice || couponPrice === 0 ? false : true} //TODO calculate coupon price with addon
              price={couponPrice ?? planPrice}
              isTrial={!!trialPlan}
              trialPeriod={trialPlan?.period}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              creditNotes={creditNotes}
              addon={addon}
              planQuantity={planQuantity}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={isVatExempt}
              onVatExemptChange={v => setVatExtempt(v)}
            />
          </div>
        )
      case 'direct_debit':
        return (
          <div className={style.tabInner}>
            <DirectDebitPayment
              ref={e => {
                paymentFormsRef.current.direct_debit = e
                toggleSubmit(true)
              }}
              type='standard'
              userToken={token}
              plan={plan}
              planInfo={planInfo}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              showCoupon={couponPrice || couponPrice === 0 ? false : true} //TODO calculate coupon price with addon
              price={couponPrice ?? planPrice}
              isTrial={!!trialPlan}
              trialPeriod={trialPlan?.period}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              creditNotes={creditNotes}
              addon={addon}
              planQuantity={planQuantity}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={isVatExempt}
              onVatExemptChange={v => setVatExtempt(v)}
            />
          </div>
        )
      default:
        return (
          <div className={style.tabInner}>
            <StripePayment
              ref={e => {
                console.log('loaded ref', selectedPayment)
                paymentFormsRef.current[selectedPayment] = e
                toggleSubmit(true)
              }}
              key={`${selectedPayment}-payment`}
              type='standard'
              method={selectedPayment}
              userToken={token}
              plan={plan}
              planInfo={planInfo}
              coupon={coupon}
              setCoupon={setCoupon}
              setCouponPrice={setCouponPrice}
              showCoupon={couponPrice || couponPrice === 0 ? false : true} //TODO calculate coupon price with addon
              price={couponPrice ?? planPrice}
              isTrial={!!trialPlan}
              trialPeriod={trialPlan?.period}
              onSuccess={onPaymentSucceded}
              onCouponSubmit={submitCoupon}
              creditNotes={creditNotes}
              addon={addon}
              planQuantity={planQuantity}
              setErrorMessage={setPaymentError}
              togglePaymentLoading={setLoadingPayment}
              isVatExempt={isVatExempt}
              onVatExemptChange={v => setVatExtempt(v)}
            />
          </div>
        )
    }
  }

  if (!error) {
    return (
      <>
        <LiveChat />
        <Header type='light' />
        <Container className={style.container}>
          {loading ? (
            <div className={style.loaderContainer}>
              <Loader type='TailSpin' color='black' height={50} width={50} className='mt-5' />
            </div>
          ) : (
            <>
              <Row className='justify-content-center '>
                <Col xs={12} md={6} lg={5}>
                  <h1 className={style.title}>Completa il pagamento ed entra ora su Learnn</h1>
                </Col>
                <Col xs={12} md={5} lg={4}></Col>
              </Row>
              <Row className='justify-content-center'>
                <Col xs={12} md={6} lg={5} className='pr-md-5'>
                  <Carousel
                    options={{ speed: 600 }}
                    grabCursor={false}
                    navigation={{
                      prevEl: `.prev-payment`,
                      nextEl: `.next-payment`,
                    }}
                    kind={applePaySupported && googlePaySupported ? 'paymentFive' : 'paymentFour'}>
                    <CarouselSlide key={`payment-card`}>
                      <div
                        onClick={() => setSelectedPayment('card')}
                        className={
                          selectedPayment === 'card' ? style.activeTab : style.inactiveTab
                        }>
                        <div className={style.paymentName}>
                          <FontAwesomeIcon icon={faCreditCard} className={style.paymentIcon} />{' '}
                          Carta
                        </div>
                      </div>
                    </CarouselSlide>
                    <CarouselSlide key={`payment-paypal`}>
                      <div
                        onClick={() => {
                          toggleSubmit(false)
                          setSelectedPayment('paypal')
                        }}
                        className={
                          selectedPayment === 'paypal' ? style.activeTab : style.inactiveTab
                        }>
                        <div className={style.paymentName}>
                          <FontAwesomeIcon icon={faPaypal} className={style.paymentIcon} /> PayPal
                        </div>
                      </div>
                    </CarouselSlide>
                    {googlePaySupported ? (
                      <CarouselSlide key={`payment-google`}>
                        <div
                          onClick={() => {
                            toggleSubmit(false)
                            setSelectedPayment('google-pay')
                          }}
                          className={
                            selectedPayment === 'google-pay' ? style.activeTab : style.inactiveTab
                          }>
                          <div className={style.paymentName}>
                            <FontAwesomeIcon icon={faGoogle} className={style.paymentIcon} /> Google
                          </div>
                        </div>
                      </CarouselSlide>
                    ) : (
                      <></>
                    )}
                    {applePaySupported ? (
                      <CarouselSlide key={`payment-apple`}>
                        <div
                          onClick={() => {
                            toggleSubmit(false)
                            setSelectedPayment('apple-pay')
                          }}
                          className={
                            selectedPayment === 'apple-pay' ? style.activeTab : style.inactiveTab
                          }>
                          <div className={style.paymentName}>
                            <FontAwesomeIcon icon={faApple} className={style.paymentIcon} /> Apple
                          </div>
                        </div>
                      </CarouselSlide>
                    ) : (
                      <></>
                    )}
                    <CarouselSlide key={`payment-debit`}>
                      <div
                        onClick={() => setSelectedPayment('direct_debit')}
                        className={
                          selectedPayment === 'direct_debit' ? style.activeTab : style.inactiveTab
                        }>
                        <div className={style.paymentName}>
                          <FontAwesomeIcon icon={faBuildingColumns} className={style.paymentIcon} />
                          SEPA
                        </div>
                      </div>
                    </CarouselSlide>
                  </Carousel>
                  {renderPaymentTab()}
                </Col>
                <Col xs={12} md={5} lg={4}>
                  <h4 className={style.summaryTitle}>Riepilogo</h4>
                  <div className={style.summary}>
                    <PlanDetails
                      type='standard'
                      plan={plan}
                      planInfo={planInfo}
                      coupon={coupon}
                      couponInvoiceName={couponInvName}
                      setCoupon={setCoupon}
                      setTrialPlan={setTrialPlan}
                      setCouponPrice={setCouponPrice}
                      setDiscountDetails={setDiscountDetails}
                      planPrice={planPrice}
                      couponPrice={couponPrice}
                      trial={trialPlan}
                      onCouponSubmit={submitCoupon}
                      creditNotes={creditNotes}
                      addon={addon}
                      planQuantity={planQuantity}
                      discountDetails={discountDetails}
                      customPrice={customPrice}
                      isVatExempt={isVatExempt}
                    />
                    {submitVisible ? (
                      <SubmitButton
                        isSubmitting={false}
                        error={paymentError}
                        loading={paymentLoading}
                        type='standard'
                        onSubmit={() => paymentFormsRef.current[selectedPayment]?.submit()}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
        <Footer />
      </>
    )
  } else {
    return <></>
  }
}

export default (props: IPaymentScreenProps) => {
  return (
    <EnsureRegistration>
      <PaymentScreen {...props} />
    </EnsureRegistration>
  )
}
