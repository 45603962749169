import Loader from 'react-loader-spinner'
import style from './submitButton.module.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { matchQuery } from 'src/utils/matchQuery'
import { useCheckout } from '../../CheckoutContext'
import { isMethodValid } from '../../utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/Option'

type SubmitButtonProps = {
  text?: string
}

export const SubmitButton = ({ text }: SubmitButtonProps) => {
  const { estimateQuery, submitPayment, paymentState, defaultPaymentMethodQuery } = useCheckout()

  const renderState = ({disabled}: {disabled?: boolean}) => {
    switch (paymentState.state) {
      case 'loading':
        return (
          <button className={style.button} disabled onClick={() => submitPayment()}>
            <Loader type='TailSpin' color='white' height={30} width={30} />
          </button>
        )
      case 'ready':
        return (
          <button className={style.button} disabled={disabled} onClick={() => submitPayment()}>
            {text ?? 'INIZIA ORA ➔'}
          </button>
        )
      case 'error':
        return (
          <>
            <div className={style.error} role='alert'>
              {paymentState.message}
            </div>
            <button className={style.button} disabled={disabled} onClick={() => submitPayment()}>
              {text ?? 'INIZIA ORA ➔'}
            </button>
          </>
        )
      case 'success':
        return (
          <button className={style.button} disabled onClick={() => submitPayment()}>
            {text ?? 'INIZIA ORA ➔'}
          </button>
        )
    }
  }

  return (
    <>
      {
        matchQuery(estimateQuery, {
          loading: () => (
            <SubmitButtonSkeleton />
          ),
          error: () => <></>,
          success: () => matchQuery(defaultPaymentMethodQuery, {
            loading: () => (
              <SubmitButtonSkeleton />
            ),
            error: () => <></>,
            success: (defaultPaymentMethod) => {
              return (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {
                    pipe(
                      defaultPaymentMethod,
                      O.fold(
                        () => renderState({disabled: true}),
                        (defaultPaymentMethod) => renderState({disabled: !isMethodValid(defaultPaymentMethod)})
                      )
                    )
                  }
                  <div className={style.privacyContainer}>
                    <span className={style.privacyText}>Completando il pagamento accetti i</span>
                    <a
                      href='https://learnn.com/termini-e-condizioni/'
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span className={style.privacyTextBold}>Termini del servizio</span>
                    </a>
                  </div>
                </div>
              )
            }
          })
        })
      }
    </>
  )
}

export const SubmitButtonSkeleton = () => {
  return (
    <SkeletonTheme baseColor='#a3a3a3' highlightColor='#cacaca'>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '100%', maxWidth: '250px', height: '50px' }}>
          <Skeleton style={{ width: '100%', height: '50px' }}></Skeleton>
        </div>
        <Skeleton style={{ marginTop: '20px', width: '300px', height: '10px' }}></Skeleton>
      </div>
    </SkeletonTheme>
  )
}
