import React, { createContext, useState, useContext, useEffect, FC } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { useHistory } from 'react-router-dom'
import { GlobalProviderContext } from 'src/components/GlobalProvider'
import { Estimate } from './types'
import {
  AddonPaymentResult,
  addonPurchase,
  getPrimaryPaymentMethod,
  PaymentMethodType,
  upgradeDowngrade,
  UpgradeDowngradeResult,
} from 'src/controllers/Payment'
import { getCheckoutEstimate, isUserFree } from './utils'
import { getCurrentSubscription } from 'src/controllers/User'
import { matchQuery } from 'src/utils/matchQuery'
import * as O from 'fp-ts/Option'

/**
 * Context Types
 */
export type CheckoutData = {
  plan?: string
  planQuantity?: number
  coupon?: string
  addon?: string
  addonQuantity?: number
  userId: string
  nextStep?: string
}
type CheckoutContextType = {
  checkoutData: CheckoutData
  setCheckoutData: React.Dispatch<React.SetStateAction<CheckoutData>>
  estimateQuery: UseQueryResult<Estimate>
  defaultPaymentMethodQuery: UseQueryResult<O.Option<PaymentMethodType>>
  setCoupon: (couponCode: string) => unknown
  checkoutState: CheckoutState
  couponState: CouponState
  paymentState: PaymentState
  submitPayment: Function
}
const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined)

type CheckoutState = { state: 'loading' } | { state: 'ready' } | { state: 'error'; message: string }
type PaymentState =
  | { state: 'loading' }
  | { state: 'ready' }
  | { state: 'error'; message: string }
  | { state: 'success' }
type CouponState = { state: 'loading' } | { state: 'ready' } | { state: 'error'; message: string }

type InvoiceData =
  | ({ type: 'addon' } & AddonPaymentResult)
  | ({
      type: 'upgrade-downgrade'
      planName?: string
      addonValue?: number
      discount?: boolean
      discountAmount?: number
      total?: number
    } & UpgradeDowngradeResult)

/**
 * Url Types
 */

type CheckoutProviderProps = {
  initialCheckoutData: CheckoutData
  onPaymentSuccess: (checkoutData: CheckoutData, invoiceData: InvoiceData) => unknown
  redirectToPayment: (checkoutData: CheckoutData) => unknown
}
export const CheckoutProvider: FC<CheckoutProviderProps> = ({
  children,
  initialCheckoutData,
  onPaymentSuccess,
  redirectToPayment,
}) => {
  const history = useHistory()
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId

  const [checkoutData, setCheckoutData] = useState<CheckoutData>(initialCheckoutData)

  const [checkoutState, setCheckoutState] = useState<CheckoutState>({ state: 'ready' })
  const [couponState, setCouponState] = useState<CouponState>({ state: 'ready' })
  const [paymentState, setPaymentState] = useState<PaymentState>({ state: 'ready' })

  const estimateQuery = useQuery(
    ['estimate', checkoutData],
    () => getCheckoutEstimate(checkoutData),
    { refetchOnWindowFocus: false },
  )
  const currentSubscriptionQuery = useQuery(
    ['currentSubscription', checkoutData.userId],
    () => getCurrentSubscription(),
    { refetchOnWindowFocus: false },
  )
  const defaultPaymentMethodQuery = useQuery(['defaultPaymentMethod', checkoutData.userId], () =>
    getPrimaryPaymentMethod(),
  )

  useEffect(() => {
    if (!userId) {
      history.push('/login')
    }
  }, [userId, history])

  useEffect(() => {
    if (estimateQuery.status === 'error') setCheckoutState({ state: 'error', message: '' })
  }, [estimateQuery.status])

  useEffect(() => {
    matchQuery(currentSubscriptionQuery, {
      loading: () => {
        if (currentSubscriptionQuery.isLoading) {
          setCheckoutState({ state: 'loading' })
        }
      },
      error: () => {
        setCheckoutState({ state: 'error', message: 'Abbonamento utente non trovato' })
      },
      success: data => {
        const isUserFreeAllowed = () => {
          return (
            isUserFree(data) &&
            checkoutData.coupon !== undefined &&
            checkoutData.addon !== undefined
          )
        }

        if (isUserFree(data) && !isUserFreeAllowed()) {
          redirectToPayment(checkoutData)
        }
        if (currentSubscriptionQuery.isSuccess) {
          setCheckoutState({ state: 'ready' })
        }
      },
    })
  }, [currentSubscriptionQuery.status])

  const setCoupon = async (couponCode: string) => {
    const isValidEstimateWithCoupon = async () => {
      try {
        await getCheckoutEstimate({ ...checkoutData, coupon: couponCode })
        return true
      } catch (e) {
        return false
      }
    }

    setCouponState({ state: 'loading' })
    if (await isValidEstimateWithCoupon()) {
      setCheckoutData(prev => ({ ...prev, coupon: couponCode }))
      setCouponState({ state: 'ready' })
    } else {
      setCouponState({ state: 'error', message: 'Coupon non valido' })
    }
  }

  const submitPayment = async () => {
    let invoiceData
    try {
      setPaymentState({ state: 'loading' })
      if (checkoutData.plan) {
        if (estimateQuery.data) {
          invoiceData = {
            addonValue: estimateQuery.data.lines.filter(x => x.entityType == 'addon')[0]?.amount,
            discount:
              estimateQuery.data.discountLines && estimateQuery.data.discountLines.length > 0
                ? true
                : false,
            discountAmount: estimateQuery.data.discountLines?.reduce(
              (acc, curr) => acc + curr.amount,
              0,
            ),
            total: estimateQuery.data.total,
            planName: estimateQuery.data.lines.filter(x => x.entityType == 'plan')[0]?.name,
          }
        }
        const result = await upgradeDowngrade({
          coupon: checkoutData.coupon,
          plan: checkoutData.plan,
          planQuantity: checkoutData.planQuantity,
          addon: checkoutData.addon,
        })
        invoiceData = { ...invoiceData, ...result, type: 'upgrade-downgrade' }
      } else if (checkoutData.addon) {
        const result = await addonPurchase({
          coupon: checkoutData.coupon,
          addonId: checkoutData.addon,
          addonQuantity: checkoutData.addonQuantity ?? 1,
        })
        invoiceData = { ...result, type: 'addon' }
      }
      setPaymentState({ state: 'success' })
      onPaymentSuccess(checkoutData, invoiceData)
    } catch (e) {
      setPaymentState({
        state: 'error',
        message: "Si è verificato un errore. Contatta l'assistenza",
      })
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkoutData,
        setCheckoutData,
        estimateQuery,
        defaultPaymentMethodQuery,
        setCoupon,
        submitPayment,
        checkoutState,
        couponState,
        paymentState,
      }}>
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => {
  const context = useContext(CheckoutContext)
  if (!context) {
    throw new Error('useCheckout must be used within a CheckoutProvider')
  }
  return context
}
