import { FeaturesConfig } from '@learnn/sdk/src/api/feature-flag'
import useAnalytics from '../../../analytics/useAnalytics'
import { useQuery } from 'react-query'
import { getCourseResources } from '../../../controllers/Course'
import React, { useEffect } from 'react'
import { AssistantTab } from './AssistantTab'
import { LessonBrowseTab } from './LessonBrowserTab'
import { NoteTab } from './NoteTab'
import { PlayerSideContentProps } from './PlayerSideContent'
import { TranscriptionTab } from './TranscriptionTab'
import { DescriptionTab } from './DescriptionTab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@learnn/designn'

export type Tab = {
  label: string
  onClick?: Function
  component: React.ReactNode
  leftComponent?: React.ReactNode
  disabled?: boolean
}

export type SetupTabsConfig = PlayerSideContentProps & {
  featureConfig: FeaturesConfig
  userId: string
  resourcesEnabled?: boolean
}

export const setupTabs = ({
  transcript,
  featureConfig,
  courseId,
  moduleId,
  lessonId,
  subtitles,
  lessonDescription,
  courseName,
  title,
  currentLessonId,
  userId,
  state,
  viewTime,
  onBlockClick,
  resourcesEnabled,
}: SetupTabsConfig): Tab[] => {
  const {
    logSidebarShowLessons,
    logSidebarShowNotes,
    logSidebarShowTranscipt,
    logSidebarShowResources,
    logAiPlayerAssistantOpen,
  } = useAnalytics()
  const resourcesQuery = useQuery(['courseResources', courseId], () => getCourseResources(courseId))
  const { colors } = useTheme()

  useEffect(() => {
    if (featureConfig['ai-assistant'] && transcript)
      logAiPlayerAssistantOpen(courseId, moduleId, lessonId)
  }, [])

  const tabs: Tab[] = [
    ...(featureConfig['ai-assistant'] && transcript
      ? [
          {
            label: 'AI',
            onClick: () => logAiPlayerAssistantOpen(courseId, moduleId, lessonId),
            component: (
              <AssistantTab courseName={courseName} lessonName={title} transcript={transcript} />
            ),
          },
        ]
      : []),
    {
      label: 'Moduli',
      onClick: () => logSidebarShowLessons(courseId, moduleId, lessonId),
      component: (
        <LessonBrowseTab
          userId={userId}
          currentLessonId={currentLessonId}
          currentModuleId={moduleId}
          courseId={courseId}
          state={state}
        />
      ),
    },
    {
      label: 'Note',
      onClick: () => logSidebarShowNotes(courseId, moduleId, lessonId),
      component: (
        <NoteTab courseId={courseId} lessonId={lessonId} moduleId={moduleId} viewTime={viewTime} />
      ),
    },
    ...(subtitles && subtitles.length > 0
      ? [
          {
            label: 'Trascrizione',
            onClick: () => logSidebarShowTranscipt(courseId, moduleId, lessonId),
            component: (
              <TranscriptionTab
                subtitles={subtitles}
                viewTime={viewTime}
                onBlockClick={onBlockClick}
              />
            ),
          },
        ]
      : []),
    ...((resourcesQuery.status === 'success' && resourcesQuery.data) || lessonDescription
      ? [
          {
            label: 'Risorse',
            onClick: () => logSidebarShowResources(courseId, moduleId, lessonId),
            component: <DescriptionTab courseId={courseId} description={lessonDescription} />,
            ...(!resourcesEnabled && {
              disabled: true,
              leftComponent: (
                <FontAwesomeIcon icon={faLock} size='sm' style={{ color: colors.text.dimmed }} />
              ),
              component: <></>,
            }),
          },
        ]
      : []),
  ]

  return tabs
}
