import React, { useEffect } from 'react'
import { Menu } from './Menu'
import { setupMenu } from './structure'
import { getPositionIndexByUrl } from './utils'

import { useFeatureFlag } from '../FeatureFlag/useFeatureFlag'
import { useAssistantPopup } from '../AssistantPopup/useAssistantPopup'

export interface IMenuProviderProps {
  children: React.ReactChild | React.ReactChild[]
}
export type Navigation = {
  menuPosition: [number, number]
  mobileMenuStatus: 'opened' | 'closed'
  menu: Menu
}

export const navigationInitialState: Navigation = {
  menuPosition: [0, 0],
  mobileMenuStatus: 'closed',
  menu: [],
}
const defaultDispatch: React.Dispatch<Partial<Navigation>> = () => navigationInitialState

function reducer(state: Navigation, newState: Partial<Navigation>) {
  return { ...state, ...newState }
}

export const MenuProviderContext = React.createContext({
  state: navigationInitialState,
  dispatch: defaultDispatch,
})

export const MenuProvider = ({ children }: IMenuProviderProps) => {
  const { features } = useFeatureFlag()
  const { open } = useAssistantPopup()
  const menu = setupMenu({
    featureFlag: features,
    onAiClick: open
  })
  const menuPosition = getPositionIndexByUrl(menu, location.pathname)

  useEffect(() => {
    dispatch({
      menu: setupMenu({
        featureFlag: features,
        onAiClick: open
      })
    })
  }, [features])

  const [state, dispatch] = React.useReducer<React.Reducer<Navigation, Partial<Navigation>>>(
    reducer,
    {
      menuPosition,
      mobileMenuStatus: 'closed',
      menu,
    },
  )

  return (
    <MenuProviderContext.Provider value={{ state, dispatch }}>
      {children}
    </MenuProviderContext.Provider>
  )
}
