import { FC } from 'react'
import { Markdown, VerticalStack, useTheme } from '@learnn/designn'
import { Message } from './types'
import { AssistantMessageSources } from './AssistantMessageSources'
type AssistantMessageItemProps = {
  message: Message
}

export const AssistantMessageItem: FC<AssistantMessageItemProps> = ({ message }) => {
  const { spacing } = useTheme()

  switch (message.role) {
    case 'assistant':
      return (
        <VerticalStack mb={spacing.space_0}>
          <Markdown size='md'>
             ## Learnn Assistant
          </Markdown>
          <Markdown size='sm'>{message.data}</Markdown>
          {
            message.sources && message.sources.length > 0 && (
              <AssistantMessageSources sources={message.sources} />
            )
          }
         
        </VerticalStack>
      )
    case 'user':
      return (
        <VerticalStack mb={spacing.space_0}>
          <Markdown size='md'>
             ## Tu
          </Markdown>
          <Markdown size='sm'>{message.data}</Markdown>
        </VerticalStack>
      )
  }
}
